export const MESSAGE_GROUP_STATE_UPDATE_FRAGMENT = `
  fragment messageGroupStateUpdateFragment on MessageGroupState {
    uid
    lastReadTimestamp
    hideMessageBeforeTimestamp
    muted
    group {
        uid
    }
    user {
        uid
        firstName
        lastName
        jobTitle
        pictureFileResource {
            uid
            schemaCode
            path
        }
    }
  }
`;
